import React from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';

function Sustainability() {
  const navigate = useNavigate();
  const goToinnovation = () => {
    navigate('/innovation');
  }
  return (
    <section className="sustainability">
      <div className="container">
        <div className="image-container">
          <img src="/assets/tanker.jpeg" alt="Sustainability" />
        </div>
        <div className="content">
          <h2>Sustainable & responsible</h2>
          <p>Rottank values safety and sustainability with great importance. Our internal safety procedures and systems are based on our "zero spills – no incidents" objectives.</p>
          <div className="icons">
            <div className="icon">
              <img src="https://cdn-icons-png.flaticon.com/512/4341/4341139.png" alt="Quality" />
              <span>Quality</span>
            </div>
            <div className="icon">
              <img src="https://cdn-icons-png.flaticon.com/512/2491/2491921.png" alt="Safety" />
              <span>Safety</span>
            </div>
            <div className="icon">
              <img src="https://cdn-icons-png.flaticon.com/512/4185/4185148.png" alt="Sustainability" />
              <span>Sustainability</span>
            </div>
          </div>
          <button onClick={goToinnovation} className="btn btn-primary">OUR INNOVATION</button>
        </div>
      </div>
    </section>
  );
}

export default Sustainability;