import React from "react";
import "./Home.css";

function LatestNews() {
  return (
    <section className="latest-news">
      {/* <div className="container-1">
        <h2>Latest News</h2>
        <div className="news-grid">
          <div className="news-content">
            <h3>Work for an independent tanker operator with a committed workforce</h3>
            <p>VT is an independent company with committed employees. Many have been living a maritime existence from generation to generation. Through innovation and skilled services, VT offers optimal support for transport and bunkering with highly skilled personnel and equipment to provide.</p>
            <button className="btn btn-primary">WORK FOR VT GROUP: OUR JOBS</button>
          </div>
          <div className="news-video">
            <iframe 
              width="560" 
              height="315" 
              src="https://www.youtube.com/embed/dQw4w9WgXcQ" 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
            </iframe>
          </div>
        </div>
      </div> */}
      <div className="container-2">
        <div>
          <h2>About</h2>
          <div className="about-1-wrapper">
            <div className="about-1">
              <p>
                Rottank Storage and Logistics Limited , a global leader in the
                storage and handling of bulk liquid products. We are a leading
                independent storage company for oils, chemicals and gases, and
                are a trusted partner in the logistics of bulk liquids
                worldwide. We are a global company with a local approach, and
                our network of storage terminals and tank containers is
                strategically located in key markets around the world. Our
                terminals are located in the heart of major oil and gas
                producing regions, and are connected to the world’s major
                shipping routes. We are committed to providing our customers
                with the highest level of service, and we are dedicated to
                ensuring the safety and security of our operations. We are proud
                to be a trusted partner in the storage of bulk liquids
                worldwide, and we are committed to providing our customers with
                the highest level of service.
              </p>
              <p>
                We are a large scale independent oil hub terminal in the Port of
                Rotterdam with VLCC access to handle crude and the full spectrum
                of petroleum products. Market place and trading hub with
                opportunities for intra and inter terminal trading, blending and
                optimization of the logistics of our customers. Strategically
                located in Europe’s biggest refinery center with pipeline
                connections to 10 refineries and connections to the main product
                pipelines including jet fuel pipeline connections to the key
                airports in North West Europe.
              </p>
            </div>
            <div className="about-1">
              <h3> Rottank Storage and Logistics Limited</h3>
              <p>
                Rottank Storage and Logistics Limited Netherlands is the leading
                independent storage and handling services provider for liquid
                bulk energy products in North Western Europe. In this position,
                we serve society by supporting critical energy supply chains.
              </p>
              <p>
                With our terminals located in the Netherlands we provide high
                quality services to our customers. Our ambition is to be the
                safety and sustainability leader in our industry by focusing on
                care for people, planet and profit. Safety is our first priority
                and at our terminals we continuously seek options to reduce our
                own environmental footprint. We continue to invest in our assets
                and deliver solid financial returns to Rottank. We are
                developing new infrastructure solutions to facilitate the
                introduction of future vital products, focusing on low-carbon
                and renewable hydrogen, CO2, flow batteries and sustainable
                feedstocks.
              </p>
            </div>
            <div className="about-2">
              <h3>For all commercial inquiries, please contact</h3>
              <p>
                {" "}
                <a href="mailto:info@rottank.com">info@rottank.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LatestNews;
