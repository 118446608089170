import React from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';



function GlobalMaritime() {
  const navigate = useNavigate();
  const goToAbout = () => {
    navigate('/about');
  }
  const goToServices = () => {
    navigate('/services');
  }
  const goToContact = () => {
    navigate('/contact');
  }
  
  return (
    <section className="global-maritime">
      <div className="container">
        <div className="content">
          <h2>Rottank Storage and Logistic limited</h2>
          <p>Rottank has always been a respected and well-known inland tanker operator ever since its foundation in 2000.</p>
          <p>Rotank today has built on a proven track record of more than 24 years experience in the maritime logistics and tanker management, steadily growing into a global leader.</p>
          <p>Since 2005, Rotank has been active in Rotterdam under the flag of Rottank storage, management and with the acquisition of Ridout Tankers in 2005, Rottank has grown into a large international shipowner with operations in the Netherlands, Panama, Spain, Sweden,and Portugal.</p>
          <div className="buttons">
            <button onClick={goToAbout} className="btn btn-primary">READ ABOUT Rottank</button>
            <button onClick={goToServices} className="btn btn-secondary">OUR SERVICES</button>
            <button onClick={goToContact} className="btn btn-secondary">WHERE WE OPERATE</button>
          </div>
        </div>
        <div className="map-container">
          <div className="map-glow"></div>
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/World_map_-_low_resolution.svg/1200px-World_map_-_low_resolution.svg.png" alt="World Map" className="world-map" />
        </div>
      </div>
    </section>
  );
}

export default GlobalMaritime;

